<template>
  <article
    class="common-general-grid-block-text"
    ref="block"
    :class="{
      'is-alternative': alternative,
    }"
  >
    <!-- Content -->
    <div class="common-general-grid-block-text__content m-top-24px">
      <div v-html="item.text" />
    </div>
  </article>
</template>

<script>
export default {
  name: 'CommonGeneralGridBlockText',
}
</script>

<script setup>
const props = defineProps({
  item: Object,
  alternative: Boolean,
})
</script>

<style lang="scss">
@import './style.scss';
</style>
