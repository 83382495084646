<template>
  <div v-if="props.items" ref="gridParallax" class="grid-parallax">
    <div
      v-for="(item, index) in props.items"
      :key="item.id"
      :ref="(el) => setRefs(index, el)"
      class="grid-parallax__block"
      :class="{
        'is-small': index % 2 == 0 ? props.reverse : !props.reverse,
        'is-left': index % 2 == 0,
        'is-right': index % 2 !== 0,
        'is-single': props.items?.length === 1,
      }"
    >
      <div class="grid-parallax__block__content">
        <template v-if="item.__typename === 'ProjectRecord'">
          <ListingProjectBlock
            :alternative="true"
            :item="item"
            :key="item.id"
          />
        </template>
        <template
          v-if="
            item.__typename === 'GridBlockItemRecord' ||
            item.__typename === 'ImageVideoBlockRecord'
          "
        >
          <PagesCommonGeneralGridBlock
            :item="item"
            :key="item.id"
            :alternative="true"
            :retination="props.retination"
            :class="{
              '--has-text': item.sideText,
              '--small-height': item.smallHeight,
            }"
          />
        </template>
        <template v-if="item.__typename === 'GridBlockItemTextRecord'">
          <PagesCommonGeneralGridBlockText
            v-if="props.items.length > 1 && !item.fullWidth"
            :item="item"
            :key="item.id"
            :alternative="true"
            :class="{ '--side-text': props.items.length > 1 }"
          />
          <WidgetIntroTextBlock v-else :text="item.text" />
        </template>
        <template v-if="item.__typename === 'TwoColumnsGridTextRecord'">
          <PagesCommonTwoColTextBlock
            :item="item"
            :key="item.id"
            class="m-top-l-2"
          />
        </template>
        <template v-if="item.__typename === 'ServicesBlockRecord'">
          <PagesTechnologyServices
            :title="item.title"
            :services="item.texts"
            :key="item.id"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PagesCommonGridParallax',
}
</script>

<script setup>
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  reverse: Boolean,
  retination: Boolean,
})

const gridParallax = ref(null)
const leftBlock = ref(null)
const rightBlock = ref(null)

const setRefs = (index, el) => {
  if (index % 2 == 0) {
    leftBlock.value = el
  } else {
    rightBlock.value = el
  }
}

onMounted(() => {
  if (rightBlock?.value) {
    const rightElement = rightBlock.value
    if (rightElement) {
      gsap.to(rightElement, {
        yPercent: !props.reverse ? -25 : 15,
        ease: 'none',
        scrollTrigger: {
          trigger: gridParallax.value,
          start: 'bottom bottom',
          end: 'bottom top',
          scrub: true,
        },
      })
    }
  }

  if (leftBlock.value) {
    const leftElement = leftBlock.value
    if (leftElement) {
      gsap.to(leftElement, {
        yPercent: props.reverse ? -25 : 15,
        ease: 'none',
        scrollTrigger: {
          trigger: gridParallax.value,
          start: 'bottom bottom',
          end: 'bottom top',
          scrub: true,
        },
      })
    }
  }
  ScrollTrigger.refresh()
})
</script>

<style lang="scss">
@import './style.scss';
</style>
